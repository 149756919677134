import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Breakdown: New Grid Critters Site",
  "date": "2018-05-24",
  "promo": "grids",
  "description": "How I built the new gridcritters.com site with CSS Grid",
  "color": "#59f4e4"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When I first came up with the idea for a CSS Grid Mastery Game, I had no idea whether people would dig it enough to pay real cash monies for it. I didn't want to waste too much time or energy building a fancy website for my game, just in case it was a flop. I took maybe a day or so and slapped together this ugly little text wall and called it good:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/old-grid-site.jpg",
        "alt": "old grid site"
      }}></img></p>
    <p>{`I made around \\$30k from sales at launch. This gave me enough runway — and more importantly enough `}<em>{`hope`}</em>{` — to keep going. I couldn't believe how awesome people were. I had no choice but to do a `}<a parentName="p" {...{
        "href": "/post/gratitude-project"
      }}>{`gratitude project`}</a>{` to express my thanks. And still... I didn't update my crappy website.`}</p>
    <p>{`A few weeks ago I decided it was finally time to make the site look as cool as the game it represented. I want to share with you the breakdown of how I built this site with CSS Grid, some of the cool UI things I did on this site, and some lessons learned. I'll be building a similar layout in flexbox for the new `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` site in the future so you can see how the two layout tools compare.`}</p>
    <p>{`Here's the brand new site `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`gridcritters.com`}</a>{` if you want to check out the final result.`}</p>
    <h2>{`Grids FTW`}</h2>
    <p>{`I used CSS Grid `}<em parentName="p">{`everywhere`}</em>{` for this site, and it was awesome. The first three sections are contained in a two-column, three row grid. Here's how I divided it up:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/site-design-sections.gif",
        "alt": "sections as a grid"
      }}></img></p>
    <p>{`The code looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.intro {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3.2em;
}
`}</code></pre>
    <p>{`I split it into two equal sized columns (the new `}<inlineCode parentName="p">{`fr`}</inlineCode>{` unit is so freaking great). I didn't bother defining my three rows though, I just let the grid create them `}<em parentName="p">{`implicitly`}</em>{` — which grids do whenever they have more content than cells or when spanning/positioning items outside the defined grid. The default size for implicit tracks is `}<inlineCode parentName="p">{`auto`}</inlineCode>{`, which means it will match the size of the track's content.`}</p>
    <p>{`So in this grid I get three `}<inlineCode parentName="p">{`auto`}</inlineCode>{` sized rows for free without having to even define them. Always good to know those defaults! The `}<inlineCode parentName="p">{`grid-row-gap`}</inlineCode>{` puts a bit of spacing between the rows, but not on the top or bottom edges of the grid which is exactly what I wanted here. Gaps are awesome, definitely one of my `}<a parentName="p" {...{
        "href": "/post/shiny-new-grid-tools/"
      }}>{`favorite new tools`}</a>{` that CSS Grid gives us.`}</p>
    <h2>{`Centered, Readable Text`}</h2>
    <p>{`I positioned my text into one of the `}<inlineCode parentName="p">{`fr`}</inlineCode>{` columns. The default behavior of text in a Grid cell is `}<inlineCode parentName="p">{`justify-items: stretch`}</inlineCode>{`. This makes the text stretch out as big as the column it's in. So my text started by taking up half of the screen:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/text-stretch.jpg",
        "alt": "justify-items: stretch takes up full cell width"
      }}></img></p>
    <p>{`Text that wide is hard to read, so I gave the text a `}<inlineCode parentName="p">{`max-width: 50ch`}</inlineCode>{` to limit each line to roughly twelve to fifeteen words. But now something interesting happens: the text is no longer as wide as the `}<inlineCode parentName="p">{`1fr`}</inlineCode>{` grid cell it's living in. And `}<inlineCode parentName="p">{`stretch`}</inlineCode>{` doesn't apply to items that have an explicit width. What ends up happening then is that the text shows up way over on the left:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/text-left.jpg",
        "alt": "justify-items: stretch appears like left"
      }}></img></p>
    <p>{`All we have to do then is center the text relative to its grid column:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.text {
  justify-self: center;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gif/text-centered.gif",
        "alt": "centered text with justify-self"
      }}></img></p>
    <p>{`And there we have it! Readable text centered perfectly in its grid column.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/text-centered.jpg",
        "alt": "text centered in grid column with justify-self"
      }}></img></p>
    <h2>{`Background Illustrations`}</h2>
    <p>{`These illustrations show the game's main character Meg as an everyday coder, struggling with CSS like we all have. I wanted parts of these images to overlap into the text areas, allowing just a bit of those faint grid line patterns to shine through.`}</p>
    <p>{`By default grid items take up just one cell each. But a cool trick that's especially useful for background images is to position them into the `}<em parentName="p">{`same`}</em>{` cell as other grid items. I used `}<inlineCode parentName="p">{`grid-area`}</inlineCode>{` to make these images take up `}<em parentName="p">{`both`}</em>{` columns of the grid.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.master-it-pic {
  grid-area: 2 / 1 / 3 / 3;
}
`}</code></pre>
    <p>{`This code means: start at row 2, column 1. End at row 3, column 3. In other words, take up the entire second row of this grid. `}<inlineCode parentName="p">{`grid-area`}</inlineCode>{` is just a shorthand for positioning items by grid lines, using either numbers like I did here or by `}<a parentName="p" {...{
        "href": "/post/naming-css-grid-lines/"
      }}>{`named grid lines`}</a>{`.`}</p>
    <p>{`The result of overlapping the illustration grid item and the text grid item is a really nice, less boxy effect:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/full-background-image.jpg",
        "alt": "overlapping grid items"
      }}></img></p>
    <h2>{`SVG Level Previews`}</h2>
    <p>{`The next part of the page is a preview of each of the chapters. For these I made a short screencast of me playing that level. I then exported them as animated gifs but they were `}<strong parentName="p">{`giant`}</strong>{`, like 10mb each. Turns out browsers simply can't handle that many giant gifs at a time, much less the network delay fetching such massive files. I converted them to .mp4 videos instead and that made them way smaller and better performing.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/level-previews.jpg",
        "alt": "level previews"
      }}></img></p>
    <p>{`To create those fun crazy-shaped futuristic outlines I used the same `}<a parentName="p" {...{
        "href": "/post/dynamic-svg-components/"
      }}>{`dynamic SVG`}</a>{` technique that I used in the game itself.`}</p>
    <p>{`These previews are themselves a CSS Grid.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.chapter {
  display: grid;
  grid-template-rows: 70px auto auto;
}
`}</code></pre>
    <p>{`Using `}<inlineCode parentName="p">{`auto`}</inlineCode>{` for the last two rows let them scale to the size of their content.`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/chapter-preview.gif",
        "alt": "chapter preview grid"
      }}></img></p>
    <h2>{`Responsive Grid`}</h2>
    <p>{`Here's another thing the CSS spec peeps really nailed this time around. Grid really is `}<a parentName="p" {...{
        "href": "/post/grid-for-responsive-layout/"
      }}>{`perfect for responsive layout`}</a>{`. When the browser is less than 1220px I simply change the grid positioning of my text items so that they occupy both columns and a row `}<em parentName="p">{`under`}</em>{` their illustration instead of by its side:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.master-it {
  grid-column: 1 / 3;
  grid-row: 4;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/gc-mobile-site.jpg",
        "alt": "responsive css grid"
      }}></img></p>
    <p>{`This makes the site appear as single column for mobile. Looking back named grid areas probably would have been a better approach for this, as I would have only had to update the single `}<inlineCode parentName="p">{`grid-template-areas`}</inlineCode>{` property inside the media queries.`}</p>
    <p>{`The futuristic SVG border tops didn't look too good on mobile, so I scaled them vertically with a CSS transform, which chopped off the top part. Then I gave it a solid border to complete the shape again only flat, which looked a lot better on small browsers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.chapter svg {
  transform-origin: bottom center;
  transform: scaleY(1.1);
  overflow: hidden;
  border-top: 2px solid;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flattened-shapes.jpg",
        "alt": "flattened shapes"
      }}></img></p>
    <h2>{`Lessons Learned`}</h2>
    <ul>
      <li parentName="ul">{`After shipping my new site I saw significant boost in sales. Turns out a good-looking site is super important :)`}</li>
      <li parentName="ul">{`I had to scrap some early designs because I did them without first having my actual content written. `}<strong parentName="li">{`Content first`}</strong>{` is still a very good rule to follow.`}</li>
      <li parentName="ul">{`I started building this site desktop first, then halfway through changed my media queries to start with mobile size and work up to desktop. `}<strong parentName="li">{`mobile first`}</strong>{` is still the way to go.`}</li>
      <li parentName="ul">{`giant gifs are bad, videos are way better`}</li>
      <li parentName="ul">{`I discovered that it's still possible to make videos `}<strong parentName="li">{`autoplay`}</strong>{` on iOS, the secret is to make sure the videos have no audio, they use the "autoplay" attribute, and the "playsinline" attribute. Otherwise iOS just shows a picture instead of playing the video.`}</li>
      <li parentName="ul">{`CSS Grid is amazing not just for apps but for websites as well.`}</li>
    </ul>
    <h2>{`Challenge`}</h2>
    <p>{`Build a nice little CSS Grid website for your side project. Potential users will think more highly of your project, and you'll learn a bunch while you're at it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      